@tailwind base;
@tailwind components;
@tailwind utilities;


.zoom {
    transition: transform 0.3s ease-in-out;
}
.zoom:hover {
    transform: scale(1.1);
}